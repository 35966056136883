import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

export interface Size {
  width: number;
  height: number;
}

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState<Size>({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', debounce(handleResize, 500));
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isIpadProView = windowSize ? windowSize.width > 992 && windowSize.width < 1366 : false;
  const isIpadView = windowSize ? windowSize.width > 667 && windowSize.width < 993 : false;
  const isMobileHorizontalView = windowSize
    ? windowSize.width > 575 && windowSize.width < 768
    : false;
  const isMobileView = windowSize ? windowSize.width < 576 : false;

  return { windowSize, isMobileView, isIpadProView, isIpadView, isMobileHorizontalView } as const;
}
