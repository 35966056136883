import { useSearchParams } from 'next/navigation';
import { useCallback, useEffect, useRef, useState } from 'react';

import { getListUseCase } from '@/services/usecase';
import type { IUsecaseItem } from '@/types/usecase';

const PAGE_SIZE = 20;

const useUseCase = (tagName?: string, modelId?: number) => {
  const [loading, setLoading] = useState(false);
  const dataRef = useRef([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState<IUsecaseItem[]>([]);
  const searchParam = useSearchParams();

  const tag = searchParam.get('tag') ?? '';
  const modelIds = searchParam.get('modelIds') ?? modelId ?? null;
  const nsfw = searchParam.get('nsfw') ?? false;
  const createdAt = searchParam.get('createdAt') ?? 'DESC';
  const weekViews = searchParam.get('weekViews') ?? 'DESC';
  const category = searchParam.get('category') ?? 'hot';
  const name = searchParam.get('name') ?? null;

  const getInputParams =
    tag !== 'Nsfw'
      ? useCallback(
          (page: number, category: string) => ({
            offset: PAGE_SIZE * (page - 1),
            size: PAGE_SIZE,
            sort:
              category === 'hot'
                ? `{"weekViews":"${weekViews}"}`
                : `{ "createdAt": "${createdAt}"}`,
            filter: `{"name":${name ? `"${name}"` : null},"tag":"${
              tagName || tag
            }","modelIds":${modelIds},"isNsfw":${nsfw ? null : nsfw} }`,
          }),
          [createdAt, name, tag, modelIds, tagName, nsfw, weekViews],
        )
      : useCallback(
          (page: number, category: string) => ({
            offset: PAGE_SIZE * (page - 1),
            size: PAGE_SIZE,
            sort:
              category === 'hot'
                ? `{"weekViews":"${weekViews}"}`
                : `{ "createdAt": "${createdAt}"}`,
            filter: `{"name":${name ? `"${name}"` : null},"tag":"${
              tagName || tag
            }","modelIds":${modelIds} }`,
          }),
          [createdAt, name, tag, modelIds, tagName, nsfw, weekViews],
        );

  const getUseCaseURL = (id: number | string) => `/template/${id}`;

  const handleGetListUseCase = async (page: number) => {
    setLoading(true);
    const res = await getListUseCase(getInputParams(page, category));

    if (page === 1) {
      dataRef.current = [];
      setData([]);
    }
    dataRef.current = dataRef.current.concat(res.data.items);
    setData(dataRef.current);
    setLoading(false);
  };

  const loadMore = (page: number) => {
    setCurrentPage(page);
    handleGetListUseCase(page);
  };

  useEffect(() => {
    handleGetListUseCase(1);
  }, [tag, modelIds, name, createdAt, category, weekViews, nsfw]);

  useEffect(() => {
    setCurrentPage(1);
  }, [tag, modelIds, name, createdAt, category, weekViews, nsfw]);

  return {
    list: data,
    loading,
    loadMore,
    currentPage,
    setCurrentPage,
    getUseCaseURL,
  };
};

export default useUseCase;
