import { Modal, ModalContent, Skeleton } from '@nextui-org/react';
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';

import GenModel from '@/contents/gen-model';
import { InpaintModelProvider, PromptProvider } from '@/provider';
import { getModelDetail, getModels } from '@/services';
import { listCampaignByModel } from '@/services/campaign';
import type { ModelLoraDetail, Models } from '@/types';
import type { IUsecaseItem } from '@/types/usecase';
import { useSQuery } from '@/utils';

interface RemixCommonProps {
  useModalRemix: any;
  remixConfig?: IUsecaseItem;
  modelSelect?: Models;
  modelLoraSelected?: ModelLoraDetail | any;
}

const RemixCommon: React.FC<RemixCommonProps> = ({
  useModalRemix,
  remixConfig,
  modelSelect,
  modelLoraSelected,
}) => {
  const [sateModelDetail, setSateModelDetail] = useState<Models>();
  const { onClose, isOpen, onOpenChange } = useModalRemix;
  const queryClient = useQueryClient();

  const { data: ModelDetail, isLoading: loadingModelDetail } = useSQuery({
    queryKey: ['ModelDetail', remixConfig?.model?.id],
    queryFn: () => {
      if (remixConfig?.model?.id) return getModelDetail(remixConfig?.model?.id);
    },
    keepPreviousData: true,
    enabled: Boolean(remixConfig?.model?.id) && isOpen,
    cacheTime: 5000,
  });

  const { data: listCampaign, isLoading: loadingListCampaign } = useSQuery({
    queryKey: ['list-campaign-model', remixConfig?.model?.id],
    queryFn: () =>
      listCampaignByModel(encodeURIComponent(`{"modelId": ${remixConfig?.model?.id}, "status":2}`)),
    keepPreviousData: true,
    enabled: Boolean(remixConfig?.model?.id),
    cacheTime: 5000,
  });

  const { data: listModels, isLoading: loadingListModels } = useSQuery({
    queryKey: ['list-models'],
    queryFn: () => getModels(30, 0),
    keepPreviousData: true,
    cacheTime: 5000,
    enabled: isOpen,
  });

  useEffect(() => {
    if (remixConfig) {
      queryClient.setQueryData(['model-config-retry'], {
        modelId: remixConfig.model.id,
        prompt: remixConfig?.prompt || '',
        processingAsset: { modelConfig: remixConfig?.processingAsset?.modelConfig },
      });
    }
  }, [remixConfig]);

  const loading = remixConfig
    ? loadingModelDetail || loadingListCampaign || loadingListModels
    : loadingListModels;

  useEffect(() => {
    if (isOpen) {
      if (ModelDetail?.data) {
        setSateModelDetail(ModelDetail?.data);
      } else if (!remixConfig) {
        if (modelSelect) {
          setSateModelDetail(modelSelect);
        } else if (modelLoraSelected) {
          switch (modelLoraSelected?.baseModel) {
            case 'SD 1.5':
              setSateModelDetail(
                listModels?.data?.items?.find((item: any) => item.type === 'Text2Image'),
              );
              break;
            case 'SDXL':
              setSateModelDetail(
                listModels?.data?.items?.find((item: any) => item.type === 'SDXL'),
              );
              break;
            default: {
              const typesModel = ['SDXL', 'Text2Image'];
              const randomModel = typesModel[Math.floor(Math.random() * typesModel.length)];
              setSateModelDetail(
                listModels?.data?.items?.find((item: any) => item.type === randomModel),
              );
              break;
            }
          }
        } else {
          const typesModel = ['SDXL', 'Text2Image'];
          const randomModel = typesModel[Math.floor(Math.random() * typesModel.length)];
          setSateModelDetail(
            listModels?.data?.items?.find((item: any) => item.type === randomModel),
          );
        }
      }
    }
    return () => setSateModelDetail(undefined);
  }, [ModelDetail, listModels, isOpen]);

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      scrollBehavior="inside"
      placement="center"
      hideCloseButton
      classNames={{ wrapper: 'overflow-hidden' }}
    >
      <ModalContent className="relative h-[calc(100dvh-30px)] max-h-none min-h-[calc(100dvh-30px)] w-[calc(100vw-30px)] min-w-[calc(100vw-30px)] rounded-[12px] sm:!m-0">
        {(onClose) => (
          <>
            <div
              onClick={onClose}
              className="absolute right-[-10px] top-[-10px] z-10 flex h-[28px] w-[28px] cursor-pointer items-center justify-center rounded-full border-3 border-white bg-gray-50"
            >
              <span>&#10005;</span>
            </div>
            {listModels?.data && !loading && sateModelDetail ? (
              <PromptProvider isRemix>
                <InpaintModelProvider>
                  <GenModel
                    models={listModels?.data}
                    modelDetail={sateModelDetail}
                    campaigns={listCampaign?.data || []}
                    isRemix
                    setSateModelDetail={setSateModelDetail}
                    modelLoraSelected={modelLoraSelected}
                  />
                </InpaintModelProvider>
              </PromptProvider>
            ) : (
              <Skeleton className="w-full h-full rounded-[12px] bg-gray-400 !duration-100" />
            )}
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default RemixCommon;
